var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"loading":_vm.loading,"paginate":true,"items":_vm.items,"sorty-by":"parametros-datas"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Parâmetro por Datas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(342),expression:"342"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchParametrosData((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.id_tipo_evento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("tipoEvento")(item.id_tipo_evento))+" ")]}},{key:"item.id_tipo_dado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("tipoDado")(item.id_tipo_dado))+" ")]}},{key:"item.data_inicial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicial))+" ")]}},{key:"item.data_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.mes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mes")(item.mes))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(344),expression:"344"}],attrs:{"icon":"","color":"orange","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(726),expression:"726"}],staticClass:"ml-2",attrs:{"icon":"","color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)}),_c('ModalParametroData',{attrs:{"parametrosDatas":_vm.parametrosDatas,"dialog":_vm.dialog,"labelBtn":_vm.labelBtn},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }