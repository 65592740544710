<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card class="pa-3">
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          :filters="{ id_band: '2,4' }"
          v-model="filters.id_band"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Tipo Evento"
          item-text="tipo_evento"
          item-value="id_tipo_evento"
          service="comissaoService.tipoEvento"
          prepend-inner-icon="mdi-calendar-text"
          v-model="filters.id_tipo_evento"
          clearable
          :filters="{ ativo: 'S' }"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Indicador"
          service="sistemaService.indicadores"
          v-model="filters.id_indicador"
          item-text="indicador"
          item-value="id_indicador"
          prepend-inner-icon="mdi-finance"
          :filters="{ ativo: 'S' }"
          clearable
          chips
          single-line
          :hasCode="true"
          :multiple="false"
          @change="handleFilter()"
        />

        <DatePickerMonth @date="assignDateFilter($event)" />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "Filtro",

  components: {
    BaseFilter,
    FilterBottom,
    DatePickerMonth
  },

  data() {
    return {
      filters: {
        ativo: "S"
      }
    };
  },
  methods: {
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    },

    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
