<template>
  <v-dialog v-model="dialog" persistent max-width="60%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Parametro por Data"
                : "Cadastrar Parametro por Data"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center">
            <v-col lg="4" md="4" cols="12">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                :filters="{ id_band: '2,4' }"
                :rules="[rules.required]"
                service="sistemaService.tipoBandeiras"
                v-model="formParametroData.id_band"
                :isCompany="true"
              />
            </v-col>
            <v-col lg="4" md="4" cols="12">
              <BaseSelect
                label="Indicador"
                v-model="formParametroData.id_indicador"
                service="sistemaService.indicadores"
                item-text="indicador"
                item-value="id_indicador"
                prepend-inner-icon="mdi-finance"
                clearable
                :rules="[rules.required]"
                :filters="{ ativo: 'S' }"
                :hasCode="true"
                :multiple="false"
                :disabled="locked"
              >
              </BaseSelect>
            </v-col>
            <v-col lg="4" md="4" cols="12">
              <BaseRangeDatePicker
                label="Data Inicio e Data Fim"
                @change="
                  formParametroData.dataInicial = $event[0];
                  formParametroData.dataFinal = $event[1];
                "
                :preDates="dates"
              />
            </v-col>

            <v-col lg="4" md="4" cols="12">
              <BaseSelect
                label="Tipo Evento"
                v-model="formParametroData.id_tipo_evento"
                service="comissaoService.tipoEvento"
                item-text="tipo_evento"
                item-value="id_tipo_evento"
                prepend-inner-icon="mdi-calendar-text"
                clearable
                :rules="[rules.required]"
                :filters="{ id_tipo_evento: 'C' }"
                :multiple="false"
                :disabled="locked"
                @change="fetchTipoFechamento()"
              >
              </BaseSelect>
            </v-col>
            <v-col lg="4" md="4" cols="12">
              <BaseSelect
                label="Tipo Dado"
                v-model="formParametroData.id_tipo_dado"
                service="comissaoService.tipoDados"
                item-text="descricao"
                item-value="id_tipo_dado"
                prepend-inner-icon="mdi-calendar-end"
                clearable
                :rules="[rules.required]"
                :filters="{ status: 'S' }"
                :multiple="false"
                :disabled="formParametroData.id_tipo_evento == undefined"
                @change="fetchTipoFechamento()"
              />
            </v-col>

            <v-col lg="4" md="4" cols="12">
              <v-autocomplete
                label="Tipo Fechamento"
                v-model="formParametroData.id_tipo_fechamento"
                :items="selects.tipoFechamento.itens"
                item-text="textTipoFechamento"
                item-value="id_tipo_fechamento"
                :value-comparator="comparator"
                prepend-inner-icon="mdi-calendar-end"
                :rules="[rules.required]"
                clearable
                chips
                :disabled="
                  formParametroData.id_tipo_evento == undefined ||
                    formParametroData.id_tipo_dado == undefined
                "
                :multiple="false"
                :readonly="locked"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
import comparator from "@/mixins/comparatorValue";
import BaseRangeDatePicker from "@/components/shared/BaseRangeDatePicker";
import { parse, format } from "date-fns";

export default {
  name: "ModalParametroData",
  mixins: [rules, comparator],

  components: {
    BaseSelect,

    BaseRangeDatePicker
  },
  props: {
    parametrosDatas: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10)
      ],
      formParametroData: {
        data_inicial: new Date().toISOString().substr(0, 10),
        data_final: new Date().toISOString().substr(0, 10)
      },

      selects: {
        tipoFechamento: {
          itens: []
        }
      },
      filters: {},
      locked: false
    };
  },
  watch: {
    parametrosDatas(value) {
      this.formParametroData = value;
      this.$set(
        this.dates,
        0,
        this.formParametroData.data_inicial.substr(0, 10)
      );
      this.$set(this.dates, 1, this.formParametroData.data_final.substr(0, 10));
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await comissao()
          .parametrosDatas()
          .store(this.formParametroData, {
            notification: true,
            menssage: "Parametro dada cadastrada com sucesso!"
          });
      }
      this.close();
    },
    async put() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await comissao()
          .parametrosDatas(this.formParametroData.parametrosDatas)
          .update(this.formParametroData, {
            notification: true,
            menssage: "Parametro editado com sucesso!"
          });
      }
      this.close();
    },
    formatDataPeriodoFechamento(periodoFechamento) {
      if (periodoFechamento.data_inicio && periodoFechamento.data_final) {
        const dataInicioParsed = parse(
          periodoFechamento.data_inicio.substring(0, 10),
          "yyyy-MM-dd",
          new Date()
        );
        const dataFimParsed = parse(
          periodoFechamento.data_final.substring(0, 10),
          "yyyy-MM-dd",
          new Date()
        );
        const dataInicioFormated = format(dataInicioParsed, "dd/MM/yyyy");
        const dataFimFormated = format(dataFimParsed, "dd/MM/yyyy");
        return `${dataInicioFormated} - ${dataFimFormated}`;
      }
      return "Não Informado";
    },
    converteDate(date) {
      return date
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-")
        .replaceAll("-", "/");
    },
    async fetchTipoFechamento() {
      let {
        data: { data }
      } = await comissao()
        .tipoFechamento()
        .show({
          per_page: -1,
          id_tipo_dado: this.formParametroData.id_tipo_dado,
          id_tipo_evento: this.formParametroData.id_tipo_evento,
          ...this.filters
        });

      this.selects.tipoFechamento.itens = data.map(item => {
        item["textTipoFechamento"] = `${this.converteDate(
          item.data_inicio
        )} - ${this.converteDate(item.data_final)}`;
        return item;
      });
    }
  },
  mounted() {
    this.fetchTipoFechamento();
  }
};
</script>
