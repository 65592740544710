<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sorty-by="parametros-datas"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Parâmetro por Datas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="342"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchParametrosData((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.id_tipo_evento`]="{ item }">
        {{ item.id_tipo_evento | tipoEvento }}
      </template>

      <template v-slot:[`item.id_tipo_dado`]="{ item }">
        {{ item.id_tipo_dado | tipoDado }}
      </template>

      <template v-slot:[`item.data_inicial`]="{ item }">
        {{ item.data_inicial | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_final`]="{ item }">
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.mes`]="{ item }">
        {{ item.mes | mes }}
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-btn
          icon
          v-can-access="344"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="726"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalParametroData
      :parametrosDatas="parametrosDatas"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import BaseSegmento from "@/components/shared/BaseSegmento";
import Filtro from "./Filtro";
import ModalParametroData from "./ModalParametroData";
import comissao from "@/services/http/comissaoService";
import dateMixin from "@/mixins/dateMixin";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";

// import serviceParametros from "@/services/parametros";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    BaseSegmento,
    Filtro,
    ModalParametroData,
    RegisterBottom
  },

  mixins: [dateMixin],

  filters: {
    tipoEvento(idTipoEvento) {
      const tiposEventos = {
        P: "PPLR",
        C: "COMISSAO",
        M: "CAMPANHA"
      };
      return tiposEventos[idTipoEvento] || idTipoEvento;
    },

    tipoDado(idTipoDado) {
      const tiposDados = {
        X: "Personalizado",
        R: "Mês Fechado",
        M: "Mês Ref. 26 a 25"
      };
      return tiposDados[idTipoDado] || idTipoDado;
    },

    mes(numeroMes) {
      const meses = {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro"
      };
      return meses[numeroMes] || numeroMes;
    }
  },

  data() {
    return {
      dialog: false,
      parametrosDatas: {},
      labelBtn: "",
      filters: {},
      headers: [
        { text: "Segmento", value: "id_band" },
        {
          text: "ID Tipo Fechamento",
          value: "id_tipo_fechamento",
          align: "end"
        },
        { text: "Tipo Evento", value: "id_tipo_evento" },
        { text: "Tipo Dado", value: "id_tipo_dado" },
        { text: "ID Indicador", value: "id_indicador", align: "end" },
        { text: "Indicador", value: "indicador" },
        { text: "Data Inicial", value: "data_inicial" },
        { text: "Data Final", value: "data_final" },
        { text: "Mês", value: "mes" },
        { text: "Ano", value: "ano" },
        { text: "Ações", value: "acoes", sortable: false, align: "center" }
      ],
      items: [],
      loading: false
    };
  },

  methods: {
    editItem(item) {
      this.parametrosDatas = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async fetchParametrosData(filters) {
      this.loading = true;
      const { data } = await comissao()
        .parametrosDatas()
        .show({
          per_page: -1,
          ativo: "S",
          parametrosDatas: "S",
          ...filters
        });
      this.items = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.fetchParametrosData(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar Parametro?",
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .parametrosDatas(item.id_param_data)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchParametrosData(this.filters);
      }
    }
  },

  async mounted() {
    await this.fetchParametrosData();
  }
};
</script>
